import React from "react";
import Layout from "../components/Layout";
import SEO from "../components/SEO";
import ContactForm from '../components/ContactForm'
import Img from "gatsby-image";
import HowItWorks from "../components/HowItWorks";
import AgentvsSellFastSactable from '../components/AgentvsSellFastSacTable'
import SeeTheDifferenceSidebar from '../components/SeeTheDifferenceSidebar'
import arrowimg from '../img/down-arrow.png'

const GetACashOfferToday = () => {
  return (
    <Layout headerTransparent={false}>
      <SEO
        title={'Get a Cash Offer for Your House or Land in Sacramento | 916-237-9457'}
        description={'Need to sell your house or land in Sacramento? Get a cash offer from our team and close in as little as 7 days no matter the condition of the property.'}
        keywords={''}
      />

      <section className="py-16 section-stripe-bg bg-gray-50 ">
        <div className="flex flex-wrap px-6 md:px-8 container mx-auto ">
          <div className="w-full md:w-2/3 md:pr-4 prose">

            <h1 className="has-text-align-center">Get A Cash Offer For Your House or Land in Sacramento!</h1>

            <div className="wp-block-image">
              <figure className="float-right is-resized">
                <img loading="lazy" src={arrowimg} alt="fill in the quick form below to sell your property" width="180" height="323" title="sell your property" />
              </figure>
            </div>

            <p>Hiring a Sacramento real estate agent isn’t always the best way to sell your house or land. The process can be expensive, time-consuming, and the sale can fall through at the last minute. </p>

            <p>A direct sale to Sell Your Home For Cash Sacramento is a different process entirely. When you sell to us, we can immediately eliminate your stress. You will not have to worry about evicting tenants, showing your property to strangers every weekend, or waiting for a buyer’s financing to come through. If you decide to sell to us, we will provide you with a non-refundable cash deposit for your house or land. </p>

            <h3><span>Why Do You Need To Sell Your House or Land in Sacramento?</span></h3>

            <ul>
              <li><b>Inherited</b><span> a property?</span></li>
              <li><span>Need to stop a foreclosure?</span></li>
              <li><span>Going through a </span><b>divorce</b><span>?&nbsp;</span></li>
              <li><span>Payments </span><b>behind</b><span>?</span></li>
              <li><span>Need to </span><b>relocate</b><span>?</span></li>
              <li><span>Got a </span><b>vacant lot or house</b><span>?&nbsp;</span></li>
              <li><span>Facing </span><b>major repairs</b><span>?&nbsp;</span></li>
              <li><span>Tired of being a </span><b>landlord</b><span>?</span></li>
            </ul>

            <p><span>You don’t have to face problems like those alone. Our team is here to help. It does not matter if you live in the house, have tenants, or are dealing with probate. Whether it's a house or vacant land, we’d love to make you a fair, no-obligation <a href="/sell-your-house/" data-type="page">cash offer</a>.</span> We can close in as little as 1 week and we always follow through on what we offer.</p>

            <h2 className="has-text-align-center"><strong>Get An Offer For Your House or Land in Sacramento!</strong></h2>

            <p className="has-text-align-center"><strong>Fill out the quick form below</strong>&nbsp;or call us at 916-237-9457 to get an offer on your house or land TODAY!</p>

          </div>
          <div className="w-full md:w-1/3 md:pl-4">

            <div className="rounded-xl mb-2 shadow bg-white mt-6 md:mt-0">
              <div className="px-8 pt-8">
                <h2 className="text-gray-800 font-bold text-2xl md:text-3xl leading-8">Get Your Fair Cash Offer: Start Below!</h2>
                <p className="text-gray-600 mb-2 leading-6 mt-1">We buy houses and land in any condition — no commissions, no fees, and no obligation whatsoever. Start below by giving us a bit of information about your property or call 916-237-9457.</p>
              </div>

              <div className="p-8 pt-4">
                <ContactForm stacked={true} hideLabels={true} showPlaceholders={true} />
              </div>
            </div>

            <SeeTheDifferenceSidebar />
          </div>
        </div>
      </section>
    </Layout>
  );
};

export default GetACashOfferToday;
